<template>
  <div class="w-sm-100 w-xs-100">
    <!--Crypto name and logo-->
    <vs-row>
      BTC logo Bitcoin
    </vs-row>
    <!--    Crypto buying and selling price-->
    <vs-row vs-type="flex">
      <vs-col vs-w="6">
        Buying price
      </vs-col>
      <vs-col vs-w="6">
        Selling price
      </vs-col>
    </vs-row>

    <!--Crypto price chart-->
    <vs-row>
      Price chart
      <div class="small">
        <trading-chart v-if="loaded" :chart-data="chartdata"></trading-chart>

      </div>
    </vs-row>

    <!--Your crypto value-->
    <vs-row vs-type="flex">
      <vs-col vs-w="6">
        Your crypto value
      </vs-col>
      <vs-col vs-w="6">
        value in sgd
      </vs-col>
    </vs-row>

    <!--Buy or sell crypto buttons-->
    <vs-row vs-type="flex">
      <vs-col vs-w="6">
        Buy crypto
      </vs-col>
      <vs-col vs-w="6">
        Sell Crypto
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
import BigNumber from 'bignumber.js';
import {SearchIcon, ChevronDownIcon} from 'vue-feather-icons'
// import { countries } from 'country-flag-icons';
// import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import CountryFlag from 'vue-country-flag'

import {debounce} from '../../helpers/helpers'
// import TradingChart from './Chart/Chart'

import TradingChart from './Chart/TradingChart.js'


export default {
  name: 'trading-individual-crypto',
  components: {
    TradingChart
  },
  data() {
    return {
      mode: process.env.VUE_APP_STAGING_ENV ? null : "production",
      chartdata : null,
      loaded : false,


    }
  },
  watch: {},
  computed: {},
  metaInfo: {
    title: 'TransCrypt - Transfer Form | Send Money Faster with Cryptocurrency',
  },
  mounted () {
    console.log("Params", this.$route.params);

    this.loaded = false
    try {
      const { userlist } = fetch('/api/userlist')
      this.chartdata = userlist;
      this.loaded = true;
    } catch (e) {
      console.error(e)
    }
  }


}

</script>
<style>


</style>